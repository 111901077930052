import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loading from './components/Loading';
import Header from './components/Header';
import Footer from './components/Footer';
import Callback from './handler/Callback';
import WrongPage from './components/WrongPage';
import Login from './components/Login';
import './styles/App.css';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const loadingTime = 1500;

    const handleLoginClick = () => {
        setIsLoggedIn(true);
    };

    const handleLogoutClick = () => {
        setIsLoggedIn(false);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, loadingTime);

        return () => clearTimeout(timer);
    }, []);

    return (
        <BrowserRouter>
            <div className="App">
                {isLoading && <Loading loadingTime={loadingTime} />}
                {!isLoading && (
                    <>
                        <Header
                            isLoggedIn={isLoggedIn}
                            onLoginClick={handleLoginClick}
                            onLogoutClick={handleLogoutClick}
                        />
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <div className="Contents">
                                        <h1>Home</h1>
                                    </div>
                                }
                            />
                            <Route path="/login" element={<Login />} />
                            <Route path="/callback/*" element={<Callback />} />
                            <Route path="*" element={<WrongPage />} />
                        </Routes>
                        <Footer />
                    </>
                )}
            </div>
        </BrowserRouter>
    );
}

export default App;
// serve -s build -l 3000