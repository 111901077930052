import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Callback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const state = params.get('state');

        console.log('Callback parameters:', { code, state });

        if (code && state) {
            const callbackUrl = state === 'github'
                ? `https://ruthcloud.xyz:3001/callback/github?code=${code}`
                : state === 'slack'
                    ? `https://ruthcloud.xyz:3001/callback/slack?code=${code}`
                    : null;


            if (callbackUrl) {
                fetch(callbackUrl)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then((data) => {
                        console.log('Response data:', data);
                        alert('Login success!');
                        // Handle user data (e.g., save to local storage or context)
                        // For example, you might set user data in context or localStorage
                        // localStorage.setItem('userData', JSON.stringify(data));
                        navigate('/');
                    })
                    .catch((error) => {
                        console.error('Error fetching user data:', error);
                        alert('Login failed! Please try again.');
                        navigate('/login');
                    });
            } else {
                console.error('Invalid state parameter.');
                alert('Invalid state parameter. Please try again.');
                navigate('/login');
            }
        } else {
            console.error('Missing code or state parameter.');
            alert('Missing code or state parameter. Please try again.');
            navigate('/login');
        }
    }, [navigate]);

    return <div>Loading...</div>;
};

export default Callback;
