import React, { useState, useEffect } from 'react';
import logo from '../logo128.png';
import style from '../styles/modules/Loading.module.css';

function Loading(props) {
    const [isLoading, setIsLoading] = useState(true);
    const loadingTime = props.loadingTime || 1000;

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, loadingTime);

        return () => clearTimeout(timer);
    }, [loadingTime]);

    if (!isLoading) {
        return null;
    }

    return (
        <div className={style.Loading}>
            <img src={logo} className={style.LoadingLogo} alt="logo" />
            <span class={style.LoadingText}>Loading...</span>
        </div>
    );
}

export default Loading;