import logo from '../logo128.png';
import style from '../styles/modules/WrongPage.module.css';

function WrongPage(props) {
    return (
        <div className={style.WrongPage}>
            <div className={style.AlertBox}>
                <h1>Error!</h1>
                <p>페이지가 삭제되었거나 존재하지 않습니다.</p>
                <p>잘못된 접근입니다. URL을 다시 확인해주세요.</p>
            </div>
        </div>
    );
}

export default WrongPage;
