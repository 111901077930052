import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../logo128.png';
import style from '../styles/modules/Header.module.css';

function Header({ isLoggedIn, onLoginClick, onLogoutClick }) {
    const navigate = useNavigate();

    const handleAuthClick = () => {
        if (isLoggedIn) {
            onLogoutClick(); // Handle logout logic
            navigate('/'); // Redirect to home page
        } else {
            onLoginClick(); // Handle login logic
            navigate('/login'); // Redirect to login page
        }
    };

    return (
        <div className={style.Header}>
            <div className={style.TitleArea}>
                <img src={logo} alt="logo" onClick={() => navigate('/')} />
                <h1 onClick={() => navigate('/')}>Ruth Cloud</h1>
                <ul className={style.MenuArea}>
                    <li
                        style={{ cursor: "pointer" }}
                        onClick={handleAuthClick}
                    >
                        {isLoggedIn ? "Logout" : "Login"}
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Header;
