import React, { useState } from 'react';
import style from '../styles/modules/Login.module.css';

const Login = () => {
    const handleSlackLogin = () => {
        window.location.href = process.env.REACT_APP_SLACK_AUTH_URL || 'https://ruthcloud.xyz:3001/auth/slack';
    };

    return (
        <div className={style.loginArea}>
            <div className={style.loginBox}>
                <h1>Login</h1>
                <GithubButton />
                <SlackButton />
            </div>
        </div>
    );
};

const GithubButton = () => {
    const handleGithubLogin = () => {
        window.location.href = 'https://ruthcloud.xyz:3001/auth/github';
    };

    return (
        <button
            onClick={handleGithubLogin}
            className={style.githubButton}
        >
            <div className={style.githubButtonText}>
                <svg xmlns="http://www.w3.org/2000/svg" className={style.githubIcon} viewBox="0 0 100 100">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z" fill="#fff" />
                </svg>
                <span>Sign in with Github</span>
            </div>
        </button>
    );
};

const SlackButton = () => {
    const handleSlackLogin = () => {
        window.location.href = 'https://ruthcloud.xyz:3001/auth/slack';
    };

    return (
        <button
            onClick={handleSlackLogin}
            className={style.slackButton}
        >
            <div className={style.slackButtonText}>
                <svg xmlns="http://www.w3.org/2000/svg" className={style.slackIcon} viewBox="0 0 122.8 122.8">
                    <path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"></path>
                    <path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"></path>
                    <path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"></path>
                    <path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"></path>
                </svg>
                <span>Sign in with Slack</span>
            </div>
        </button>
    );
};

export default Login;